import { ArrowBoxOut } from '@deepup/icons';
import {
  Button,
  Typography,
  Stack,
  Link,
  CircularProgress,
  AppBar,
  Alert,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Box, styled } from '@mui/system';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TextLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'none',
  fontWeight: 'bold',
}));

const REDIRECT_URL = 'https://conmon.deepup.io/map/welcome';

export const DeprecatedBanner = () => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const isBetweenSmMd = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isRootPath = pathname === '/';

  return (
    <AppBar
      sx={() => ({
        zIndex: 1220,
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14)',
        position: 'absolute',
        top: isRootPath ? 80 : isBetweenSmMd ? 130 : isSmall ? 100 : 155,
      })}
    >
      <Alert severity="warning" sx={{ borderRadius: 0, justifyContent: 'center' }} variant="filled">
        Cockpit will be shutdown on the 1st of March. Please visit the{' '}
        <Link color="inherit" href="https://conmon.deepup.io/" target="_blank">
          ConMon App
        </Link>{' '}
        for all the Cockpit functionalities.
      </Alert>
    </AppBar>
  );
};

export const Deprecated = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = REDIRECT_URL;
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Box
      alignItems="center"
      component="div"
      display="flex"
      flexDirection="column"
      position="relative"
      sx={{
        top: 100,
        height: 'calc(100vh - 200px)',
      }}
    >
      <Stack direction="row" p={4}>
        <Box component="div" display="flex" flexDirection="column" gap={1} py={8}>
          <Typography variant="h4">
            Cockpit doesn’t exist anymore.
            <br />
            Welcome to Construction Monitoring!
          </Typography>
          <Typography variant="body1">
            You will be automatically redirected to Construction Monitoring.
          </Typography>
          <Typography variant="body1">
            If the redirection does not work, you can access the page using this link:
          </Typography>
          <Typography variant="body1">
            <TextLink href={REDIRECT_URL}>conmon.deepup.io</TextLink>
          </Typography>
          <CircularProgress />
        </Box>
        <Box component="div">
          <img alt="Page was moved" src="/page_removed.png" />
          <Box component="div" display="flex" justifyContent="end" py={4}>
            <Button
              color="primary"
              component="a"
              href={REDIRECT_URL}
              size="medium"
              startIcon={<ArrowBoxOut fill="currentColor" height="24px" width="24px" />}
              target="_blank"
              variant="contained"
            >
              Go To Conmon
            </Button>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};
